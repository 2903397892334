<template>
  <BaseSideTray :show="cartStore.showCart" full-height @dismiss="onDismiss">
    <div class="flex h-full flex-col">
      <h2
        v-if="!hasEmptyCart"
        class="type-heading-2 md:type-heading-3 mb-6 leading-none"
      >
        Cart{{ cartLines.length ? ` – ${cartLines.length}` : '' }}
      </h2>
      <div
        v-if="hasEmptyCart"
        class="flex flex-grow flex-col items-center justify-center"
      >
        <AnimationFloatingSimple class="mb-5 w-[160px]" />
        <p class="type-body-lg mb-8">Your cart is empty</p>
        <BaseButton class="btn-lg btn-black" @click="onDismiss">
          Continue shopping
        </BaseButton>
      </div>
      <div v-else class="flex min-h-0 flex-grow flex-col">
        <div class="flex min-h-0 flex-grow flex-col overflow-y-auto">
          <div class="space-y-5">
            <CartLineItem
              v-for="cartLine in cartLines"
              :key="cartLine.id"
              :cart-line="cartLine"
              class="border-b border-cream-dark py-5 md:py-6"
            />
          </div>
          <div v-if="optionalAddons.length">
            <h3 class="type-body-sm py-4 text-black/50">Optional add-ons</h3>
            <div class="space-y-2">
              <CartAddon
                v-for="product in optionalAddons"
                :key="product.id"
                :product="product"
                @add="addAddonToCart(product.variants.nodes[0].id)"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-none flex-col pt-5">
          <div class="space-y-4">
            <BaseAlert class="type-body-sm bg-purple">
              <p>
                {{
                  hasFreeShipping || hasFreeSamplerShipping
                    ? 'Your order qualifies for free AU metro shipping!'
                    : `Spend ${$helpers.formatPrice(
                        freeShippingRemainingSpend,
                      )} more for free AU metro shipping`
                }}
              </p>
            </BaseAlert>
            <BaseAsyncButton
              :busy="cartStore.isLoading"
              class="btn btn-lg btn-black w-full"
              @click="onCheckout"
            >
              {{ $helpers.formatPrice(cartTotal) }} – Checkout
            </BaseAsyncButton>
          </div>
        </div>
      </div>
    </div>
  </BaseSideTray>
</template>

<script lang="ts" setup>
const cart = useCart()
const cartStore = useCartStore()
const { data: accessoriesData } = await useCollectionQuery('accessories')

const cartLines = computed(() => cart.value?.lines.nodes || [])
const cartTotal = computed(() => cart.value?.cost.subtotalAmount.amount || 0)

const freeShippingRemainingSpend = computed(
  () => Math.round((50 - cartTotal.value) * 100) / 100,
)
const hasFreeShipping = computed(() => freeShippingRemainingSpend.value <= 0)

const hasFreeSamplerShipping = computed(() => {
  if (cartLines.value.length === 1) {
    return useShopifyProduct(cartLines.value[0].merchandise.product).isSampler
      .value
  } else {
    return false
  }
})

const optionalAddons = computed(
  () =>
    accessoriesData.value?.collection?.products.nodes.filter(({ id }) => {
      return !cartLines.value.find(
        ({ merchandise }) => merchandise.product.id === id,
      )
    }) || [],
)

function addAddonToCart(merchandiseId: string) {
  cartStore.addCartLines({ merchandiseId, quantity: 1 })
}

const hasEmptyCart = computed(() => cartLines.value.length === 0)

function onDismiss() {
  cartStore.showCart = false
}

onKeyStroke('Escape', onDismiss)

function onCheckout() {
  window.location.href = cart.value!.checkoutUrl
}
</script>

<style lang="postcss" scoped>
.shade-enter-active,
.shade-leave-active {
  @apply transition-opacity duration-500 ease-slide;
}

.shade-enter-from,
.shade-leave-to {
  @apply opacity-0;
}
</style>

<template>
  <div class="fixed inset-0 bottom-auto z-40 w-0 md:bottom-0 md:right-auto">
    <BaseShade
      :show="uiStore.showNavMenu"
      class="fixed inset-0"
      @click="onDismiss"
    />
    <Transition name="tray">
      <div
        v-if="uiStore.showNavMenu"
        class="absolute inset-0 bottom-auto flex h-[100svh] w-screen flex-col px-[10px] py-8 transition-transform duration-700 ease-slide md:bottom-0 md:right-auto md:w-auto md:p-5"
      >
        <div
          :class="uiStore.showNavMenu ? null : 'translate-y-64'"
          class="flex h-24 flex-none items-end justify-center pb-5 transition-transform duration-700 ease-slide md:hidden"
        >
          <BaseButton
            class="btn-cream h-12 w-12 rounded-full"
            @click="onDismiss"
          >
            <SvgGlyphClose />
          </BaseButton>
        </div>
        <div
          class="relative z-[2] flex w-full flex-grow flex-col overflow-hidden rounded-[10px] bg-purple md:w-[480px]"
        >
          <div class="hidden flex-none px-7 pb-4 pt-12 md:block">
            <BaseButton
              class="h-[60px] w-[60px] rounded-full border border-black/10"
              @click="onDismiss"
            >
              <SvgGlyphClose />
            </BaseButton>
          </div>
          <div class="type-heading-1 md:type-heading-2 relative flex-grow">
            <div
              :class="showShopMenu ? '-translate-x-full' : ''"
              class="flex flex-col items-start space-y-5 px-7 py-8 transition-transform duration-500 ease-slide"
            >
              <button
                class="flex w-full items-center justify-between"
                type="button"
                @click="showShopMenu = true"
              >
                <span>Shop</span>
                <div
                  class="flex h-[36px] w-[36px] items-center justify-center rounded-full border border-black/10"
                >
                  <SvgGlyphAngleRight class="w-2" stroke-width="1.5" />
                </div>
              </button>
              <NuxtLink to="/mission" @click="onDismiss">Mission</NuxtLink>
              <NuxtLink to="/faqs" @click="onDismiss">FAQs</NuxtLink>
              <NuxtLink to="/account" @click="onDismiss">Account</NuxtLink>
            </div>
            <div
              :class="showShopMenu ? '-translate-x-full' : ''"
              class="absolute left-full top-0 flex w-full flex-col space-y-5 px-7 py-8 transition-transform duration-500 ease-slide"
            >
              <button
                class="flex items-center space-x-4 text-base leading-none text-black/50"
                type="button"
                @click="showShopMenu = false"
              >
                <SvgGlyphAngleRight class="w-2 rotate-180" stroke-width="1.5" />
                <span class="pt-0.5">Back</span>
              </button>
              <NuxtLink to="/collections/all" @click="onDismiss"
                >Shop all</NuxtLink
              >
              <NuxtLink to="/collections/plant-milks" @click="onDismiss"
                >Plant milks</NuxtLink
              >
              <div class="inline-flex items-center space-x-2">
                <NuxtLink to="/collections/bundles" @click="onDismiss"
                  >Bundles
                </NuxtLink>
                <ProductSaveBadge />
              </div>
              <NuxtLink to="/collections/accessories" @click="onDismiss"
                >Accessories</NuxtLink
              >
            </div>
          </div>
          <div class="type-body-sm px-7 pb-8">
            Sip with us
            <a :href="instagramUrl" target="_blank">@drink_nimbus</a>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
const globalsStore = useGlobalsStore()
const uiStore = useUIStore()
const showShopMenu = ref(false)
const { lg } = useTailwindBreakpoints()

const instagramUrl = computed(
  () => `https://instagram.com/${globalsStore.site?.social.instagramHandle}`,
)

function onDismiss() {
  uiStore.showNavMenu = false
  showShopMenu.value = false
}

watch(lg, (newLg) => {
  if (newLg) {
    onDismiss()
  }
})
</script>

<style lang="postcss" scoped>
.tray-enter-active,
.tray-leave-active {
  @apply transition-transform duration-700 ease-slide;
}

.tray-enter-from,
.tray-leave-to {
  @apply -translate-y-full md:-translate-x-full md:translate-y-0;
}
</style>
